<template>
    <h3>{{ $t('Edit Industry') }}</h3>
    <p>{{ typeName }}</p>

    <form @submit.prevent="updateIndustry" class="invite-form">

        <div class="form-group">
            <label for="name">Name*</label>
            <input v-model="industry.name" type="text" id="name" required class="form-control">
            <div v-if="errors[`name`]" v-for="error in errors[`name`]" :key="error" class="text-danger">
                {{ error }}
            </div>

            <FormErrors v-if="errors['message']" :errors="errors['message']"  />
        </div>

        <div class="form-group">
            <label for="price_rate">Price rate</label>
            <input v-model="industry.price_rate" type="number" id="price_rate" step="0.01" class="form-control" placeholder="Price rate">

            <FormErrors v-if="errors['price_rate']" :errors="errors['price_rate']"  />
        </div>

        <div v-if="changeImage || !industry.image" class="mb-3">
            <label for="formFile" class="form-label">Image</label>
            <input class="form-control" type="file" id="formFile" accept="image/png,image/jpg,image/jpeg" @change="file = $event.target.files[0]">

            <FormErrors v-if="errors['image']" :errors="errors['image']"  />
        </div>
        <div v-else class="form-group row">
            <a @click.prevent="changeImage=true" href=""><h2 class="text-success">Change Image</h2></a>
            <img class="card-img-top ml-4 mt-1" :src="industry.image" alt="img" style="max-height: 18rem; max-width: 18rem; object-fit: scale-down;">
        </div>

        <p>* required</p>
        <div class="form-group">
            <button class="btn btn-primary" :disabled="disabled">{{ $t('SAVE') }}</button>
        </div>
    </form>
</template>

<script>
import {industryService} from "@/services/settings/industryService";
import FormErrors from "@/components/widgets/FormErrors";

export default {
    name: "EditIndustry",
    data() {
        return {
            industry: {},
            file: null,
            changeImage: false,
        }
    },
    computed: {
        industryId() {
            return this.$route.params.id
        }
    },
    created() {
        this.getIndustry()
    },
    methods: {
        getIndustry() {
            industryService.getIndustry(this.industryId).then(response => this.industry = response.data.data)
        },
        updateIndustry() {
            this.disabled = true

            const formData = new FormData();

            if (this.file) {
                formData.append('image', this.file)
            }

            formData.append('name', this.industry.name)
            formData.append('price_rate', this.industry.price_rate)
            formData.append('_method', 'PUT')

            industryService.updateIndustry(this.industryId, formData)
                .then(response => {
                    this.industry = response.data.data
                    alert('Industry updated')
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
                .finally(() => this.disabled = false)
        },
    },
    components: {
        FormErrors
    },
}
</script>

<style scoped>

</style>